import React from "react";
import "./HeroSection.css"; // You can style your hero section in this file
import { IconContext } from "react-icons";
import { FaDownload, FaArrowRight } from "react-icons/fa";
// import GooglePlayBadge from "../../assets/images/google-play-badge.png";
import img from "../../assets/images/Screenshot1.png";
import img2 from "../../assets/images/Screenshot2.png";
import { ProductsData } from "../../data/ProductsData";

interface HeroSectionProps {
  title: string;
  subtitle: string;
  route?: string;
  body?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonVisible?: boolean;
}

const HeroSection = ({
  route,
  title,
  subtitle,
  body,
  buttonText,
  buttonLink,
  buttonVisible,
}: HeroSectionProps) => {
  const product = ProductsData.find((product) => product.id === "jengu");
  if (!product) {
  }
  return (
    <section
      className={route === "Home" ? "hero-section" : "hero-section-mini"}
    >
      <div className="hero-container">
        <div className="hero-content">
          <h1 className="hero-title">{title}</h1>
          <p className="hero-subtitle">{subtitle}</p>
          {body && <p className="hero-body">{body}</p>}
          {buttonVisible && (
            <div className="buttons-container">
              {/* <div>
              <a href={buttonLink} className="play-button">
                <img
                  src={GooglePlayBadge}
                  alt="Google Play"
                  className="google-badge"
                />
              </a>
            </div> */}
              <IconContext.Provider
                value={{ style: { verticalAlign: "middle" } }}
              >
                <a
                  href={product?.downloadLink}
                  className="hero-button button-accented"
                  style={{ marginRight: "14px" }}
                >
                  <FaDownload className="button-icon" />
                  Download
                </a>
                <a href="/product/jengu" className="hero-button button-primary">
                  <FaArrowRight className="button-icon" />
                  {buttonText}
                </a>
              </IconContext.Provider>
            </div>
          )}
        </div>
        {route === "Home" && (
          <div className="hero-image-container">
            <img src={img} alt="Jengu Screenshot" className="hero-image-left" />
            <img src={img2} alt="Jengu Screenshot" className="hero-image" />
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroSection;
