import logo from "../assets/images/logo-simple.svg";
import HeroSection from "../components/HeroSection/HeroSection";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import { HeroData } from "../data/HeroData";
import { ArticlesData } from "../data/ArticlesData";
import "./Header.css";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";
import SideDrawer from "../components/SideDrawer/SideDrawer";
import useNavigationContext from "../contexts/NavigationContext";
import { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

type HeaderProps = {
  activeLink?: string;
};

const Header = ({ activeLink }: HeaderProps) => {
  const [width] = useWindowSize();
  const { sideDrawerIsOpen, setSideDrawerIsOpen, isMobile, setIsMobile } =
    useNavigationContext();

  useEffect(() => {
    width < 1000 ? setIsMobile(true) : setIsMobile(false);
  }, [width, setIsMobile]);

  const routerParams = useParams();
  const getHeroProps = () => {
    if (activeLink === "Articles" && routerParams.articleId) {
      const articleProps = ArticlesData.filter(
        (article) => article.articleId === routerParams.articleId
      );
      return [
        { title: articleProps[0].title, subtitle: articleProps[0].subtitle },
      ];
    }
    return HeroData.filter((hero) => hero.route === activeLink);
  };

  // const heroProps = HeroData.filter((hero) => hero.route === activeLink);

  const getRotation = () => {
    return sideDrawerIsOpen ? "rotate(90deg)" : "rotate(0deg)";
  };

  const handleMenuClick = () => {
    setSideDrawerIsOpen(!sideDrawerIsOpen);
  };
  return (
    <header className="site-header">
      <div className="container">
        <div
          className="menu-icon"
          onClick={handleMenuClick}
          style={isMobile ? { display: "block" } : { display: "none" }}
        >
          <IconContext.Provider
            value={{
              size: "20",
              style: { verticalAlign: "middle", transform: getRotation() },
            }}
          >
            <FaBars className="menu-icon-bars" rotate={90} />
          </IconContext.Provider>
        </div>
        <SideDrawer activeLink={activeLink} isMobile={isMobile} />
        <div>
          <img src={logo} alt="orion cyber logo" className="logo" />
        </div>
        <NavigationBar activeLink={activeLink} isMobile={isMobile} />
      </div>
      <section className="hero">
        <HeroSection {...getHeroProps()[0]} />
      </section>
    </header>
  );
};

export default Header;
