import { createContext, useContext } from "react";

type AppContext = {
  sideDrawerIsOpen: boolean;
  setSideDrawerIsOpen: (value: boolean) => void;
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
};

export const NavigationContext = createContext<AppContext | undefined>(
  undefined
);

export default function useNavigationContext() {
  const context = useContext(NavigationContext);
  if (!context)
    throw Error("useAuthContext can only be used inside an AuthProvider");
  return context;
}
