import React, { useEffect } from "react";
import Header from "../layout/Header";
import img from "../assets/images/jengu-logo-icon.svg";
import useNavigationContext from "../contexts/NavigationContext";
import { IconContext } from "react-icons";
import { FaArrowRight, FaDownload } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ProductsData } from "../data/ProductsData";

const Products = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const product = ProductsData.find((product) => product.id === "jengu");
  const getJenguDownloadLink = () => {
    if (product) {
      return product.downloadLink;
    } else {
      return "https://play.google.com/";
    }
  };
  return (
    <div className="app-container">
      <Header activeLink="Products" />
      <div className="body-container">
        <div className="body-content">
          <div className="panel" style={{ marginLeft: "0px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "200px" }}>
                <img src={img} alt="Jengu Logo" style={{ height: "200px" }} />
              </div>
              <div style={{ paddingLeft: "14px" }}>
                <h2 style={{ marginTop: "10px" }}>Jengu Fasting App</h2>
                <p>
                  Unlock the power of fasting with Jengu, the revolutionary app
                  designed to transform your fasting journey into a seamless and
                  personalised experience. Say goodbye to generic fasting apps
                  and embrace a new era of customisation, convenience, and
                  scientific precision.
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                  }}
                >
                  <IconContext.Provider
                    value={{ style: { verticalAlign: "middle" } }}
                  >
                    <Link
                      to={getJenguDownloadLink()}
                      className="hero-button button-accented"
                      style={{ marginRight: "14px" }}
                    >
                      <FaDownload className="button-icon" />
                      Download
                    </Link>
                    <Link
                      to={"/product/jengu"}
                      className="hero-button button-primary"
                    >
                      <FaArrowRight className="button-icon" />
                      Learn More
                    </Link>
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>
          <p className="tagline">
            At Orion Cyber, we're passionate about crafting innovative mobile
            applications that make a difference in people's lives. As a sole
            developer and owner of the business, I'm dedicated to delivering
            high-quality solutions that exceed expectations.
          </p>

          <h2>Our Mission</h2>
          <p>
            Our mission at Orion Cyber is simple: to develop and deliver
            cutting-edge mobile applications that inspire, empower, and enrich
            the lives of our users. With a focus on user experience,
            functionality, and reliability, we strive to create apps that stand
            out in the ever-evolving digital landscape.
          </p>

          <h2>What We Do</h2>
          <p>
            At Orion Cyber, we specialise in developing and selling mobile
            applications for both the Apple App Store and Google Play Store.
            From productivity tools to entertainment apps, we cover a wide range
            of categories to cater to diverse user needs and preferences.
          </p>

          <h2>Why Choose Orion Cyber?</h2>
          <ul>
            <li>
              <strong>Quality Assurance:</strong> We take pride in our
              meticulous approach to development, ensuring that every app we
              create meets the highest standards of quality and performance.
            </li>
            <li>
              <strong>Personalised Service:</strong> As a sole developer, I am
              personally invested in the success of every project. You can
              expect dedicated support and a personalised approach tailored to
              your specific requirements.
            </li>
            <li>
              <strong>Innovation:</strong> Innovation is at the core of
              everything we do. We're constantly exploring new technologies and
              trends to bring fresh ideas and features to our apps, keeping them
              ahead of the curve.
            </li>
            <li>
              <strong>Customer Satisfaction:</strong> Your satisfaction is our
              top priority. We value feedback and strive to continuously improve
              our products based on user input and insights.
            </li>
          </ul>

          <h2>Get in Touch</h2>
          <p>
            Ready to bring your app idea to life or explore partnership
            opportunities? Don't hesitate to get in touch with us. We're always
            excited to collaborate on exciting projects and help turn your
            vision into reality.
          </p>

          <p>
            Thank you for considering Orion Cyber for your mobile application
            needs. We look forward to the opportunity to work with you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Products;
