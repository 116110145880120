import React, { useEffect } from "react";
import Header from "../layout/Header";
import useNavigationContext from "../contexts/NavigationContext";

const Services = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);
  return (
    <div className="app-container">
      <Header activeLink="Services" />
      <div className="body-container">
        <div className="body-content">
          <p className="tagline">
            At Orion Cyber, we're dedicated to providing comprehensive digital
            solutions to help businesses and individuals thrive in the digital
            world. As a sole developer and owner of the business, I'm committed
            to delivering exceptional results that exceed expectations.
          </p>

          <h2>Our Mission</h2>
          <p>
            Our mission at Orion Cyber is to empower our clients with
            cutting-edge digital solutions that drive success and growth.
            Whether you need a mobile application, a stunning website, or
            eye-catching graphics, we're here to bring your vision to life.
          </p>

          <h2>Our Services</h2>
          <ol>
            <li>
              <strong>Mobile Application Design and Development:</strong> From
              concept to launch, we specialise in creating custom mobile
              applications for both iOS and Android platforms. Whether you need
              a productivity app, a gaming experience, or anything in between,
              we have the expertise to deliver.
            </li>
            <li>
              <strong>Website Design and Development:</strong> Your website is
              your digital storefront, and we're here to make it shine. We offer
              comprehensive website design and development services, creating
              responsive, user-friendly websites that engage and captivate your
              audience.
            </li>
            <li>
              <strong>Web Hosting Services:</strong> Ensure your website stays
              online and performs flawlessly with our reliable web hosting
              services. We provide secure, high-speed hosting solutions tailored
              to your specific needs, keeping your website accessible 24/7.
            </li>
            <li>
              <strong>Graphic Design:</strong> Make a lasting impression with
              stunning visuals crafted by our experienced graphic designers.
              Whether you need a logo, branding materials, or promotional
              graphics, we'll create designs that reflect your brand identity
              and leave a lasting impression.
            </li>
          </ol>

          <h2>Why Choose Orion Cyber?</h2>
          <ul>
            <li>
              <strong>Expertise:</strong> With years of experience in the
              industry, we have the knowledge and skills to tackle projects of
              any size or complexity.
            </li>
            <li>
              <strong>Personalised Service:</strong> As a sole developer, I'm
              personally invested in the success of every project. You can
              expect dedicated support and a tailored approach to meet your
              unique needs.
            </li>
            <li>
              <strong>Innovation:</strong> We're passionate about staying ahead
              of the curve and embracing the latest technologies and trends to
              deliver innovative solutions that make a difference.
            </li>
            <li>
              <strong>Customer Satisfaction:</strong> Your satisfaction is our
              top priority. We work closely with you every step of the way to
              ensure we meet and exceed your expectations.
            </li>
          </ul>

          <h2>Get in Touch</h2>
          <p>
            Ready to take your digital presence to the next level?{" "}
            <a href="contact.html">Contact Us</a> today to discuss your project
            needs and let's work together to achieve your goals.
          </p>

          <p>
            Thank you for considering Orion Cyber for your digital solutions. We
            look forward to the opportunity to collaborate with you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
