import React, { useEffect } from "react";
import { ProductsData } from "../data/ProductsData";
import { Link, useParams } from "react-router-dom";
import Header from "../layout/Header";
import useNavigationContext from "../contexts/NavigationContext";
import { IconContext } from "react-icons";
import { FaDownload } from "react-icons/fa6";
import docprivacy from "../assets/docs/jengu-privacy.pdf";
import docterms from "../assets/docs/jengu-terms.pdf";

const Product = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);
  const params = useParams();

  const product = ProductsData.find(
    (product) => product.id === params.productId
  );
  if (!product) {
    return <div>Product not found</div>;
  }
  return (
    <div className="app-container">
      <Header activeLink="Products" />
      <div className="body-container">
        <div className="body-content">
          <div className="body-split">
            <div className="body-left">
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
              <img src={product?.image} alt={product?.name} />
            </div>
            <div className="body-right" style={{ paddingTop: "22px" }}>
              <IconContext.Provider
                value={{ style: { verticalAlign: "middle" } }}
              >
                <Link
                  to={product.downloadLink}
                  className="hero-button button-accented"
                >
                  <FaDownload className="button-icon" />
                  Download
                </Link>
              </IconContext.Provider>
              <h2>Quick Links</h2>
              <ul style={{ listStyle: "none", paddingLeft: "10px" }}>
                <li>
                  <Link to={docprivacy} target="_blank" download>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to={docterms} target="_blank" download>
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
