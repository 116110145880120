import { Link } from "react-router-dom";
import "./NavigationBar.css";

type HeaderProps = {
  activeLink?: string;
  isMobile?: boolean;
};

const NavigationBar = ({ activeLink, isMobile }: HeaderProps) => {
  return (
    <div className="menu">
      {isMobile ? null : (
        <>
          <Link
            to="/"
            className={`menu-item${
              activeLink === "Home" ? " menu-item-active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`menu-item${
              activeLink === "About" ? " menu-item-active" : ""
            }`}
          >
            About
          </Link>
          <Link
            to="/articles"
            className={`menu-item${
              activeLink === "Articles" ? " menu-item-active" : ""
            }`}
          >
            Articles
          </Link>
          <Link
            to="/products"
            className={`menu-item${
              activeLink === "Products" ? " menu-item-active" : ""
            }`}
          >
            Products
          </Link>
          <Link
            to="/services"
            className={`menu-item${
              activeLink === "Services" ? " menu-item-active" : ""
            }`}
          >
            Services
          </Link>
          <Link
            to="/support"
            className={`menu-item${
              activeLink === "Support" ? " menu-item-active" : ""
            }`}
          >
            Support
          </Link>
        </>
      )}

      <Link
        to="https://plesk.orioncyber.co.uk"
        className={"login-button"}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: isMobile ? "13px" : "" }}
      >
        Login
      </Link>
    </div>
  );
};

export default NavigationBar;
