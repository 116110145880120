import { useEffect } from "react";
import useNavigationContext from "../contexts/NavigationContext";
import Header from "../layout/Header";

const Support = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);
  return (
    <div className="app-container">
      <Header activeLink="Support" />
      <div className="body-container">
        <div className="body-content">
          <p className="tagline">
            At Orion Cyber, we're committed to providing exceptional support to
            ensure you have a seamless experience with our digital solutions.
            Whether you have questions, encounter technical issues, or need
            assistance with our services, our dedicated support team is here to
            help.
          </p>
          <div className="body-split">
            <div className="body-left">
              <h3>How Can We Assist You?</h3>
              <ul>
                <li>
                  <strong>General Inquiries:</strong> Have a question about our
                  services or need more information? Feel free to reach out to
                  us. Our knowledgeable team is ready to provide you with the
                  answers you need.
                </li>
                <li>
                  <strong>Technical Support:</strong> Encountering technical
                  issues with one of our products? Don't worry, we've got you
                  covered. Our technical support team is available to
                  troubleshoot and resolve any issues you may encounter,
                  ensuring you can continue to use our solutions with
                  confidence.
                </li>
                <li>
                  <strong>Feedback and Suggestions:</strong> Your feedback is
                  invaluable to us. We welcome any suggestions or ideas you may
                  have for improving our products or services. Your input helps
                  us continuously enhance our offerings to better meet your
                  needs.
                </li>
              </ul>
              <h3>Contact Us</h3>
              <p>
                Getting in touch with our support team is easy. You can reach us
                through the following channels:
              </p>
              <span style={{ color: "lime" }}>CONTACT FORM</span>
              <h3>Frequently Asked Questions (FAQs)</h3>
              <p>
                Before reaching out to our support team, you may find the
                answers to your questions in our FAQ section. We've compiled a
                list of frequently asked questions to provide you with quick
                answers and solutions to common inquiries.
              </p>
              <h3>Feedback Form</h3>
              <p>
                We value your feedback and strive to continuously improve our
                products and services based on your input. If you have any
                comments, suggestions, or feedback you'd like to share with us,
                please fill out our feedback form. Your feedback helps us better
                understand your needs and preferences, allowing us to deliver
                solutions that exceed your expectations.
              </p>
              <p>
                Thank you for choosing Orion Cyber. We're here to support you
                every step of the way.
              </p>
            </div>
            <div className="body-right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
