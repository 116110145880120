import React from "react";
import { Link } from "react-router-dom";
import "./SideDrawer.css";
import useNavigationContext from "../../contexts/NavigationContext";
import { FaCircleXmark } from "react-icons/fa6";
import { IconContext } from "react-icons";

type SideDrawerProps = {
  activeLink?: string;
  isMobile?: boolean;
};

const SideDrawer = ({ activeLink, isMobile }: SideDrawerProps) => {
  const { sideDrawerIsOpen, setSideDrawerIsOpen } = useNavigationContext();
  const sideDrawerClasses = () => {
    let returnVal = "";

    returnVal = isMobile ? "side-drawer" : "side-drawer-closed";

    returnVal += sideDrawerIsOpen ? " side-drawer-open" : " side-drawer-close";
    return returnVal;
  };
  const closeSideDrawer = () => {
    setSideDrawerIsOpen(false);
  };
  return (
    <div className={sideDrawerClasses()}>
      <div className="side-drawer-icon-close" onClick={closeSideDrawer}>
        <IconContext.Provider value={{ size: "2em" }}>
          <FaCircleXmark id="close-icon" />
        </IconContext.Provider>
      </div>
      <div className="side-drawer-items">
        <Link
          to="/"
          className={`side-drawer-item${
            activeLink === "Home" ? " side-drawer-item-active" : ""
          }`}
        >
          Home
        </Link>
        <Link
          to="/about"
          className={`side-drawer-item${
            activeLink === "About" ? " side-drawer-item-active" : ""
          }`}
        >
          About
        </Link>
        <Link
          to="/articles"
          className={`side-drawer-item${
            activeLink === "Articles" ? " side-drawer-item-active" : ""
          }`}
        >
          Articles
        </Link>
        <Link
          to="/products"
          className={`side-drawer-item${
            activeLink === "Products" ? " side-drawer-item-active" : ""
          }`}
        >
          Products
        </Link>
        <Link
          to="/services"
          className={`side-drawer-item${
            activeLink === "Services" ? " side-drawer-item-active" : ""
          }`}
        >
          Services
        </Link>
        <Link
          to="/support"
          className={`side-drawer-item${
            activeLink === "Support" ? " side-drawer-item-active" : ""
          }`}
        >
          Support
        </Link>
      </div>
    </div>
  );
};

export default SideDrawer;
