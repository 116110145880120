import React, { useEffect } from "react";
import Header from "../layout/Header";
import useNavigationContext from "../contexts/NavigationContext";

const Home = () => {
  const { setSideDrawerIsOpen } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);
  return (
    <div className="app-container">
      <Header activeLink="Home" />
      <div className="body-container">
        <div className="body-content">
          <p className="tagline">
            At Orion Cyber, we offer a range of digital solutions designed to
            help you achieve your goals and stand out in the digital landscape.
            From mobile applications to websites and graphic design services, we
            have the expertise and creativity to bring your ideas to life.
          </p>
          <div className="panel-container">
            <div className="panel">
              <h3>Mobile Applications</h3>
              <p>
                Our mobile applications are designed to deliver exceptional user
                experiences and drive engagement. Whether you need an app for
                iOS or Android, we can create custom solutions that meet your
                unique requirements.
              </p>
            </div>
            <div className="panel">
              <h3>Websites</h3>
              <p>
                A professional website is essential for establishing your online
                presence and reaching your target audience. We offer custom
                website design and development services to help you create a
                site that reflects your brand and drives results.
              </p>
            </div>
            <div className="panel">
              <h3>Graphic Design</h3>
              <p>
                Stand out from the competition with eye-catching graphic design
                services from Orion Cyber. From logos and branding to marketing
                materials and social media graphics, we can help you make a
                lasting impression on your audience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
