import "./Articles.css";
import React, { useEffect } from "react";
import Header from "../layout/Header";
import useNavigationContext from "../contexts/NavigationContext";
import { ArticlesData } from "../data/ArticlesData";
import { Link, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import { IconContext } from "react-icons";

const Articles = () => {
  const { setSideDrawerIsOpen, isMobile } = useNavigationContext();
  useEffect(() => {
    setSideDrawerIsOpen(false);
  }, [setSideDrawerIsOpen]);

  const params = useParams();
  // console.log("params: ", params.articleId);

  const renderAllArticles = () => {
    return (
      <div className="articles">
        {ArticlesData.map((article) => (
          <div key={article.articleId} className="article">
            <Link to={`/articles/${article.articleId}`}>
              <h2>{article.title}</h2>
            </Link>
            <h3>{article.subtitle}</h3>
            <p>{article.preview}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderSingleArticle = () => {
    const article = ArticlesData.find(
      (article) => article.articleId === params.articleId
    );
    if (!article) {
      return <div>Article not found</div>;
    }
    return (
      <>
        <Link
          to="/articles"
          className="button"
          style={!isMobile ? { width: "160px" } : {}}
        >
          <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
            <FaAngleLeft size="22" />
          </IconContext.Provider>
          &nbsp;All Articles
        </Link>
        <div className="body-split">
          {isMobile ? renderArticleContents() : null}
          <div className="body-left">
            <div className="article-single">
              <div dangerouslySetInnerHTML={{ __html: article.body }} />
            </div>
          </div>
          {isMobile ? null : renderArticleContents()}
        </div>
      </>
    );
  };

  const renderArticleContents = () => {
    const article = ArticlesData.find(
      (article) => article.articleId === params.articleId
    );
    if (!article) {
      return <div>Article not found</div>;
    }
    return (
      <div
        className="body-right"
        style={{ position: !isMobile ? "sticky" : "inherit", top: 0 }}
      >
        <h2>Contents</h2>
        <ul style={{ listStyle: "none", paddingLeft: "10px" }}>
          {article.contents.map(
            (content: { id: string; title: string }, index: number) => (
              <li key={index}>
                <a href={`#${content.id}`}>{content.title}</a>
              </li>
            )
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className="app-container">
      <Header activeLink="Articles" />
      <div className="body-container">
        <div className="body-content">
          {params.articleId ? renderSingleArticle() : renderAllArticles()}
        </div>
      </div>
    </div>
  );
};

export default Articles;
