import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./routes/Home";
import Products from "./routes/Products";
import About from "./routes/About";
import Services from "./routes/Services";
import Support from "./routes/Support";
import Articles from "./routes/Articles";
import Product from "./routes/Product";
import { NavigationContext } from "./contexts/NavigationContext";
import { useState } from "react";

const router = createBrowserRouter([
  {
    path: "/",
    Component: Home,
  },
  {
    path: "/about",
    Component: About,
  },
  {
    path: "/articles",
    Component: Articles,
  },
  {
    path: "/articles/:articleId",
    Component: Articles,
  },
  {
    path: "/products",
    Component: Products,
  },
  {
    path: "/product/:productId",
    Component: Product,
  },
  {
    path: "/services",
    Component: Services,
  },
  {
    path: "/support",
    Component: Support,
  },
]);

function App() {
  const [sideDrawerIsOpen, setSideDrawerIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  return (
    <>
      <NavigationContext.Provider
        value={{ sideDrawerIsOpen, setSideDrawerIsOpen, isMobile, setIsMobile }}
      >
        <RouterProvider router={router} />
      </NavigationContext.Provider>
    </>
  );
}

export default App;
